.gallery-container {
  position: relative;
  min-height: 100vh;
  display: grid;
  margin: 5% 0;
}
.gallery-container h1 {
  font-size: 2.5rem;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  color: #5b5b5b;
  font-weight: bold;
  font-family: Roboto;
  padding: 15px;
}
.gallery-container .image-gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 10px;
}
.gallery-container .image-gallery-container .image-gallery {
  height: 250px;
  width: 350px;
  border: 10px solid #fff;
  box-shadow: 10px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}
.image-gallery h2 {
  position: absolute;
  display: flex;
  font-weight: bold;
  margin-top: 7px;
  font-size: 22px;
}
.gallery-button .secondary-button {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.gallery-container .image-gallery-container .image-gallery img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.2s linear;
}
.gallery-container .image-gallery-container .image-gallery:hover img {
  transform: scale(1.1);
}
.gallery-container .popup-image {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.gallery-container .popup-image span {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.gallery-container .popup-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #fff;
  border-radius: 5px;
  width: 750px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .container .popup-image img {
    width: 95%;
  }
}
