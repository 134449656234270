@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  background: #fff; /* fallback for old browsers */
}
* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}
.navbar-logo {
  padding: 0 15px;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}

#NavBar {
  display: flex;
  z-index: 99;
}
.sticky {
  position: fixed;
  top: -2px;
  width: 100%;
  z-index: 99;
}
.navbar-mainbg {
  background-color: #087cc4;
  padding: 0px;
  height: 10px;
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: baseline;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}

#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
.navbar-text {
  color: white;
  font-weight: 700;
  background-color: #087cc4;
  overflow-y: hidden;
}

.navbar-drawer {
  background: #087cc4;
  position: fixed;
  top: 0;
  overflow-x: hidden;
  color: white;
  font-size: 15px;
  left: -5px;
  bottom: 0;
  z-index: 1200;
  width: 0;
  height: 100%;
  list-style: none;
  transition: width 0.2s ease;
}
.navbar-drawer.active {
  width: 290px;
  z-index: 99999;
}

.sidebar-logo {
  border-bottom: 1px solid #d4d3d3;
  height: 90px;
  display: flex;
  justify-content: space-evenly;
  padding: 2%;
  align-items: center;
}
.main-cross {
  color: white;
  width: 45px;
  height: 45px;
  display: none;
  justify-content: left;
  cursor: pointer;
}

.navbar-drawer a {
  color: #fff;
  text-decoration: none;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  padding: 10px;
  align-items: center;
  width: 100%;
  margin: 3%;
}
.navbar-drawer .products-size {
  font-size: 14.47px;
}
.navbar-drawer a:hover {
  background: linear-gradient(
    rgba(250, 250, 250, 0.1),
    rgba(250, 250, 250, 0.1)
  );
  border-radius: 8px;
}
.navbar-drawer li {
  display: flex;
  align-items: center;
}

.nav-link.main-link {
  color: #fff !important;
}
#navbarSupportedContent ul li a {
  color: #087cc4;
  text-decoration: none;
  font-size: 20px;
  display: block;
  padding: 20px;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

#navbarSupportedContent > ul > li.active > a {
  color: #087cc4;
  background-color: transparent;
  transition: all 0.1s;
  font-weight: 600;
}
#navbarSupportedContent > span > li.active > a {
  color: #fff;
}

#navbarSupportedContent a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.1s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

#navbarSupportedContent ul li a .nav-link.products-navbar {
  padding: 30px;
}

.navbar-brand img {
  width: 200px;
}
.navbar-icon {
  width: 30px;
  height: 30px;
  color: white;
  margin-right: 5%;
}
/* 
.navbar-drawer.active {
  opacity: 1;
  visibility: visible;
  transition: var(--speed) ease;
  z-index: 1200;
  height: 100%;
} */
/* .navbar-drawer.inactive {
  opacity: 0;
  visibility: hidden;
  transition: var(--speed) ease;
} */
.hamburger {
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}
.close-icon {
  color: white;
  font-size: large;
}
.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}
/* .hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #f9c829;
  bottom: 10px;
} */
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
/* .hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f9c829;
} */
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
/* Drop Down*/
.fruits-dropdown {
  display: none;
  position: absolute;
  top: 0;
  width: -moz-fit-content;
  width: fit-content;
  right: -242px;
  color: #087cc4;
  background-color: #fff;
}

.fruits-hover {
  text-align: left;
}
.spices-hover {
  text-align: left;
}
.dehydrated-hover {
  text-align: left;
}
.others-hover {
  text-align: left;
}
.fruits-hover:hover .fruits-dropdown {
  display: grid;
}
.spices-dropdown {
  display: none;
  position: absolute;
  top: 0px;
  right: -242px;
  width: 100%;
  color: #087cc4;
  background-color: #fff;
}
.spices-hover:hover .spices-dropdown {
  display: grid;
}
.dehydrated-dropdown {
  display: none;
  position: absolute;
  top: 137.5px;
  right: -145px;
  color: #087cc4;
  background-color: #fff;
  width: 100%;
}
.dehydrated-hover:hover .dehydrated-dropdown {
  display: grid;
}
/* .others-dropdown {
  display: none;
  position: absolute;
  top: 137.5px;
  right: -145px;
  color: #087cc4;
  background-color: #fff;
  width: 100%;
}
.others-hover:hover .others-dropdown {
  display: grid;
} */
.services {
  position: relative;
  cursor: pointer;
  padding: 50%;
  display: contents;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.about-dropdown {
  display: none;
  color: #087cc4;
  background-color: #fff;
  position: absolute;
  display: none;
  position: fixed;
  border-radius: 8px;
  top: 91px;
  align-items: center;
}
.about-us:hover .about-dropdown {
  display: grid;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  right: 34rem;
}
.about-dropdown li {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #dddcdc;
}
.about-dropdown li:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.remove_decoration {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-arrow {
  position: absolute;
  color: #087cc4;
  z-index: -1;
}
.right-arrow-sub {
  position: absolute;
  color: #087cc4;
}
.dropdown,
.sub-dropdown {
  color: #087cc4;
  background-color: #fff;
  position: absolute;
  display: none;
  position: fixed;
  border-radius: 8px;
  top: 91px;
  align-items: center;
}
/* .dropdown:hover {
  display: grid;
} */
.sub-dropdown:hover {
  display: flex;
}

/* .dropdown li + li {
  margin-top: 10px;
} */
.dropdown li {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #dddcdc;
}
.sub-dropdown li {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #dddcdc;
}
.sub-dropdown:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.dropdown li:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.services:hover .dropdown {
  display: grid;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  right: 25rem;
}
.remove_decoration:hover {
  color: #fff;
  text-shadow: -0.25px -0.25px 0 #ffd505 0.25px 0.25px #ffd505;
}
.nav-link.products-navbar {
  padding: 40px;
}
.nav-link.products-navbar:hover .dropdown {
  display: grid;
}
/*DROpDOWN*/
@media (max-width: 992px) {
  .navbar-brand img {
    width: 170px;
  }
}
@media (max-width: 799px) {
  .collapse {
    display: flex;
    flex-basis: auto;
  }
}
@media (max-width: 1036px) {
  #navbarSupportedContent ul li a {
    font-size: 17px;
  }
}
@media (max-width: 700px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}
@media only screen and (max-width: 590px) {
}
@media only screen and (max-width: 800px) {
  #navbarSupportedContent ul {
    display: none;
  }
}
#navbarSupportedContent a:not(:only-child):after {
  display: none;
}
