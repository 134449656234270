.read-container.blur.active {
  filter: blur(20px);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
.read-container .read-content {
  position: relative;
  max-width: 800px;
}
.read-container h2 {
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.read-button {
  position: relative;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #fff;
  background: #111;
}
