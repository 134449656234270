.more-container {
  position: relative;
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.more-container .more-card {
  position: relative;
  max-width: 450px;
  height: 300px;
  background-color: #e7e5e5;
  margin: 10px;
  padding: 15px;
  display: grid;
  border-radius: 22px;
}
.more-content h4 {
  text-align: center;
  font-weight: bold;
}
.more-img .img {
  transition: 0.5s all;
}
.more-img .img:hover {
  transform: scale(1.3);
}
.more-content p {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.more-container .more-card .more-img {
  width: 100%;
  flex: 0 0 150px;
}
.more-container .more-card .more-img .img {
  width: 105px;
  height: 105px;
  display: flex;
  margin: auto;
}
.more-container .more-card .more-content {
  margin-left: 20px;
}
@media (max-width: 991px) {
  .more-container {
    flex-direction: column;
    width: 100%;
  }
  .more-container .more-card {
    flex-direction: column;
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .more-container {
    flex-direction: column;
  }
  .more-container .more-card {
    flex-direction: column;
    max-width: 350px;
  }
  .more-container .more-card .more-img {
    max-width: 100%;
  }
  .more-container .more-card .more-content {
    margin-left: 0px;
  }
}
